import React from "react";
import { trialClassCheckoutRoute } from "../../utils";
import { navigate } from "@reach/router";
import "./MonterreyLandingVideo.css";
import LandingNavbar from "./LandingNavbar";
import MobileNavbar from "./MobileNavbar";

const MonterreyLandingVideo = () => {
  return (
    <div
      id="scrollspyHero"
      className="monterrey-landing__container container-fluid m-0 w-100 position-relative"
    >
      <div className="pt-3 position-absolute d-flex flex-column start-0 end-0 container__content-container">
        <LandingNavbar />
        <MobileNavbar />

        <div className="content-container__description ">
          <div className="d-flex flex-column align-items-start description__head position-relative">
            <h2 className="head__desc mb-0">
              {`ALCANZA TU MÁXIMO POTENCIAL\n
              `}
            </h2>
            <h2 className="head__desc">EVERYTHING YOU WANT IN A WORKOUT</h2>
            <h1 className="head__app-name">THE BODY METHOD</h1>
            <p className="head__city monterrey-degraded-btn text-white px-2">
              MONTERREY
            </p>
          </div>

          <div className="description__content mt-3">
            <p className="content__title">ENTRENA INTELIGENTE</p>
            <p className="content__text">
              Haz que cada segundo cuente con nuestras rutinas divertidas y
              estratégicamente planeadas.
            </p>
          </div>

          <div className="description__bottom d-flex justify-content-center mt-4">
            <button
              type="button"
              className="btn text-white bold monterrey-degraded-btn px-4"
              style={{ borderRadius: "40px", height: "50px" }}
              onClick={() => navigate(trialClassCheckoutRoute)}
            >
              AGENDA CLASE DE PRUEBA
              <i className="fas fa-arrow-right ms-2" />
            </button>
          </div>
        </div>
      </div>

      <img
        className="mw-100 w-100 container__video"
        src="https://thebodymethod.s3.us-west-1.amazonaws.com/background-hero-min.png"
        data-object-fit="cover"
        alt="hero"
      />
    </div>
  );
};

export default MonterreyLandingVideo;
