import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import { S3_ENDPOINT } from "../../utils";
import "./milestones.css";

const MilestoneAchieved = ({ milestone }) => {
  const { user } = useContext(AuthContext);
  const { clearModal } = useContext(ModalContext);

  const handleShare = () => {
    clearModal();
  }

  const backgroundImage = `url(${S3_ENDPOINT}/customer-milestone-${milestone.milestone_id}.jpg)`;

  return (
    <div
      className="text-center milestone-container"
      style={{
        backgroundImage,
      }}
    >
      <h2 className="h1 milestone-user-name text-center w-100 text-white">{user.name}</h2>
      <div className="px-3 w-100 btn-share text-center">
        <button onClick={handleShare} className="btn m-auto btn-primary">
          <i className="fab fa-instagram" /> Share
        </button>
      </div>
    </div>
  );
};

export default MilestoneAchieved;
