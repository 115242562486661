import React from "react";
import "./RewardsNavbar.css";
import { Link } from "@reach/router";

export default function RewardsNavbar() {
  return (
    <nav
      className="navbar navbar-expand-lg rewards-navbar navbar-light w-100 hide-mobile"
    >
      <div className="container-fluid navbar-container">
        <a className="navbar-brand p-2 ps-0" href="/#landing">
          <img src="/img/logo-black.png" style={{ width: "73px", height: "73px" }} />
        </a>
        <button
          className="navbar-toggler rounded-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse mw-100" id="navbarNav">
          <div className="container-fluid pe-0">
            <ul
              id="rewardsNavbar"
              className="navbar-nav nav justify-content-center position-relative"
              style={{ paddingRight: "130px" }}
            >
              <li className={`nav-item`}>
                <a className="nav-link" href="#scrollspyLevels">
                  Niveles
                </a>
              </li>
              <li className={`nav-item `}>
                <a className="nav-link" href="#scrollspySteps">
                  Steps
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#scrollspyRules">
                  Modalidades
                </a>
              </li>
           
              <li className="navbar-cta__container position-absolute end-0 bottom-0 top-0">
                <Link
                  className="btn rewards-gradient bold navbar-cta text-uppercase"
                  to="/entrar"
                >
                  <i className="fa fa-user"></i>{" "}
                  <span className="ms-1">Ingresar</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
