import React from "react";
import useEmblaCarousel from "embla-carousel-react";

import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";

import "./embla.css";
import { formatMonto } from "../../utils";
import { navigate } from "@reach/router";

const OPTIONS = { align: "start" };

const MonterreyPackagesCarousel = ({ packages }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const renderSubscriptionInterval = (class_package) => {
    const periods = {
      day: {
        plural: "DÍAS",
        singular: "DÍA",
      },
      month: {
        plural: "MESES",
        singular: "MES",
      },
      year: {
        plural: "AÑOS",
        singular: "AÑO",
      },
    };

    if (class_package?.subscription_interval) {
      const currentPeriod = periods[class_package.subscription_period];
      const periodType =
        class_package.subscription_interval > 1 ? "plural" : "singular";

      return (
        <p className="content__subscription">
          CADA {class_package.subscription_interval} {currentPeriod[periodType]}
        </p>
      );
    } else {
      return <p className="content__subscription"></p>;
    }
  };

  const renderPricePerClass = (class_package) => {
    if (class_package?.class_amount && class_package?.price) {
      const price = Number(class_package.price);
      const pricePerClass = formatMonto(
        price / Number(class_package.class_amount)
      );

      return (
        <h5 className="content__feature">
          <i className="fas fa-check me-2" />${Number(pricePerClass).toFixed(2)}{" "}
          por clase
        </h5>
      );
    }
  };

  const renderPackages = () => {
    if (Array.isArray(packages)) {
      return packages?.map((class_package) => {
        return (
          <div className="packages__slide" key={class_package.class_package_id}>
            <div
              className="embla__slide__package"
              style={{
                backgroundColor: "FAFAFA",
              }}
            >
              <div className="package-card__header">
                <p className="header__name">{class_package.title}</p>
                <p className="header__expiration">
                  {class_package.expiration_days} días de Vigencia.
                </p>
              </div>

              <div className="package-card__content" style={{ flex: 1 }}>
                <p className="content__price">
                  {class_package.price === 0 ? (
                    "GRATIS"
                  ) : (
                    <>${class_package.price} MXN</>
                  )}
                </p>
                {renderSubscriptionInterval(class_package)}

                <div className="content__features">
                  {class_package.class_amount > 0 && (
                    <h5 className="content__feature">
                      <i className="fas fa-check me-2" />
                      Reserva tus clases presenciales
                    </h5>
                  )}

                  {class_package.class_amount > 1 &&
                    class_package.free_trial_length <= 0 &&
                    class_package.include_online && (
                      <>
                        <h5 className="content__feature">
                          <i className="fas fa-check me-2" />
                          Acceso a TBM Online Gratis
                        </h5>
                      </>
                    )}

                  {renderPricePerClass(class_package)}
                </div>

                <button
                  type="button"
                  className="btn class-package__cta w-100 text-white bold px-4 monterrey-degraded-btn mb-3 mt-auto"
                  style={{
                    borderRadius: "40px",
                  }}
                  onClick={() =>
                    navigate(`/checkout/${class_package.class_package_id}/`)
                  }
                >
                  {class_package.price === 0 ? "RESERVAR" : "COMIENZA YA"}
                  <i className="fas fa-arrow-right ms-2" />
                </button>
                <p className="text-black mb-0 packages-carousel__bottom w-100 mb-2 fw-normal">
                  El cargo se realizará automáticamente a tu forma de pago.
                </p>
                <p className="text-black packages-carousel__bottom w-100 mb-2 fw-normal">
                  Puedes cancelar cuando quieras.
                </p>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <>
      <section className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">{renderPackages()}</div>
        </div>

        <div className="embla__controls">
          <div className="embla__buttons">
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </div>

          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={"embla__dot".concat(
                  index === selectedIndex ? " embla__dot--selected" : ""
                )}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default MonterreyPackagesCarousel;
