import moment from "moment";

export const setupLocale = () => {
  moment.locale("es", {
    monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_"),
    weekdaysShort: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
    weekdays: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
  });
}

//Converts a UTC String date from server response to UTC moment Date
export const convertServerStringDateToUTC = (dateString) =>
  moment.utc(dateString, "YYYY-MM-DD HH:mm:ss");

//Converts Moment UTC Date to Moment Local Date
export const convertMomentUTCToLocal = (utcDate) => utcDate.local();

export const convertToUtcString = (dateString) =>
  moment.utc(dateString).format("YYYY-MM-DD HH:mm:ss");

export const displayLocalDateTime = (dateString) =>
  convertMomentUTCToLocal(moment(dateString)).format("YYYY-MM-DD HH:mm:ss");

export const displayDateTime = (dateString) =>
  convertServerStringDateToUTC(dateString).format("YYYY-MM-DD HH:mm:ss");

export const displayTime = (dateString) =>
  convertMomentUTCToLocal(convertServerStringDateToUTC(dateString)).format(
    "HH:mm"
  );

export const displayDayTime = (dateString) =>
  convertServerStringDateToUTC(dateString).format("ddd DD, HH:mm");

export const displayDayTimeMonth = (dateString) =>
  convertServerStringDateToUTC(dateString).format("ddd DD MMM, HH:mm");

export const getStartOfDay = dateString => moment(dateString)
.utc()
.startOf("day")
.format("YYYY-MM-DD");