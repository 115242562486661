import React, { useRef, useState } from "react";
import "./RewardsRules.css";
import { Link } from "@reach/router";

export default function RewardsRules() {
  const [onSiteOpen, setOnSiteOpen] = useState(false);
  const [onlineOpen, setOnlineOpen] = useState(false);

  const onSiteBtn = useRef();
  const onlineBtn = useRef();

  const handleToggle = () => {
    const onSiteValue =
      onSiteBtn.current?.attributes["aria-expanded"].value === "true";
    const onlineValue =
      onlineBtn.current?.attributes["aria-expanded"].value === "true";

    setOnSiteOpen(onSiteValue);
    setOnlineOpen(onlineValue);
  };

  return (
    <div id="scrollspyRules" className="container-fluid rewards-rules">
      <h2 className="rules-title max-width-1200 mb-3">REWARDS RULES</h2>
      <p className="rules-desc max-width-1200 mb-3">Conoce las claves para alcanzar tus metas.</p>

      <div className="row w-100 mx-auto max-width-1200">
        <div className="col-12 col-md-6 ps-md-0 rules_col">
          <img
            className="rules__desc-image mb-4 w-100"
            src="/img/rules-on-site.png"
          />

          <div className="accordion bg-white" id="rulesOnSite">
            <div className={`accordion-item shadow mb-3 `}>
              <h2 className={`accordion-header`}>
                <button
                  ref={onSiteBtn}
                  className={`accordion-button d-flex justify-content-start align-items-center`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseRulesOnSite`}
                  aria-expanded="false"
                  onClick={handleToggle}
                  aria-controls={`collapseRulesOnSite`}
                >
                  <h2
                    className="bold item__title me-2"
                    style={{ color: "#1D1D1B" }}
                  >
                    REGLAS PRESENCIAL:
                  </h2>
                  <i
                    style={{ backgroundColor: "#1D1D1B" }}
                    className={`fas ${
                      onSiteOpen ? "fa-minus" : "fa-plus"
                    } accordion-btn-icon ms-auto`}
                  />
                </button>
              </h2>
              <div
                id={`collapseRulesOnSite`}
                className={`accordion-collapse collapse  `}
                data-bs-parent="#rulesOnSite"
              >
                <div className="accordion-body onsite-rules">
                  <ul>
                    <li className="mb-2">
                      Aplica solo paquetes desde las 8 clases
                    </li>
                    <li className="mb-2">
                      Se debe cumplir con los créditos comprados 2 meses
                      consecutivos y entras al primer nivel
                    </li>
                    <li className="mb-2">
                      Si continuas otros 2 meses consecutivos cumpliendo con los
                      créditos completos subes al nivel 2
                    </li>
                    <li className="mb-2">
                      Y así sucesivamente por todos los niveles
                    </li>
                    <li className="mt-3">
                      <strong>Clases</strong>
                    </li>
                    <div className="ps-4">
                      <li className="">8 clases: usar en 1 mes </li>
                      <li> 10clases: usar en 1 mes </li>
                      <li>16 Clases: usar en 40 Días </li>
                      <li>20 Clases: usar en 40 Días</li>
                      <li>Ilimitado: usar en 50 Días</li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 pe-md-0 rules_col">
          <img className="rules__desc-image mb-4 w-100" src="/img/rules-online.png" />
          <div className="accordion bg-white" id="rulesOnline">
            <div className={`accordion-item shadow mb-3 `}>
              <h2 className={`accordion-header`}>
                <button
                  ref={onlineBtn}
                  className={`accordion-button d-flex justify-content-start align-items-center`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseRulesOnline`}
                  aria-expanded="false"
                  aria-controls={`collapseRulesOnline`}
                  onClick={handleToggle}
                >
                  <h2
                    className="bold item__title me-2"
                    style={{ color: "#1D1D1B" }}
                  >
                    REGLAS ONLINE:
                  </h2>
                  <i
                    style={{ backgroundColor: "#1D1D1B" }}
                    className={`fas ${
                      onlineOpen ? "fa-minus" : "fa-plus"
                    } accordion-btn-icon ms-auto`}
                  />
                </button>
              </h2>
              <div
                id={`collapseRulesOnline`}
                className={`accordion-collapse collapse  `}
                data-bs-parent="#rulesOnline"
              >
                <div className="accordion-body online-rules">
                  <ul>
                    <li className="mb-2">
                      Pasas al 1 nivel cuando completas 2 meses consecutivos
                      cumpliendo con el workplanner
                    </li>
                    <li className="mb-2">
                      Para pasar al nivel 2 completando otros 2 meses
                      consecutivos del workplanner
                    </li>
                    <li className="mb-2">
                      Y Así sucesivamente al llegar al año por nivel
                    </li>

                    <li className="mt-3">
                      <strong>Tienes 3 Strikes (faltas) por mes</strong>
                    </li>
                    <div className="ps-4">
                      <li className="">8 clases: usar en 1 mes </li>
                      <li>
                        1 Strike : Se mantiene en el mismo nivel (no sube)
                      </li>
                      <li>2 Strike: Vuelves a un nivel anterior</li>
                      <li>3 Strike: Vuelves al nivel cero</li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card no-scale rules-banner max-width-1200">
        <img className="rules-card__image" src="/img/rules-banner.png" />

        <div className="d-flex flex-column rules-card__content justify-content-center">
          <h2 className="rules-card__title">
            Empoderamiento a Través del Movimiento
          </h2>
          <p className="rules-card__desc">
            Inspira, educa y empodera a la mujer con un método fitness único
            respaldado por la ciencia. Conéctate contigo misma y siente el
            cambio positivo a través del ejercicio en nuestro espacio físico y
            digital.
          </p>
          <button type="button" className="rules-btn btn rewards-gradient">
            COMIENZA YA
            <i className="fas fa-arrow-right ms-2" />
          </button>
        </div>
      </div>

      <div className="rules-bottom">
        <img
          className="rules-bottom__logo"
          src="/img/rewards-logo-footer.png"
          alt="tbm logo"
        />
      </div>

      <div className="row w-100 mx-auto max-width-1200">
        <div className="col-12 col-md-6 p-0 text-start">
          <p>The Body Method 2024, All Rights Reserved</p>
        </div>
        <div className="col-12 col-md-6 p-0 text-end">
          <Link to="#" className="rewards-footer__link">
            Privacy Policy
          </Link>
          <Link to="#" className="rewards-footer__link ms-3">
            Terms Of Use
          </Link>
          <Link to="#" className="rewards-footer__link ms-3">
            FAQ
          </Link>
        </div>
      </div>
    </div>
  );
}
