import { Link } from "@reach/router";
import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

const MobileNavbar = () => {
  const [display, setDisplay] = useState(false);
  const { user } = useContext(AuthContext);

  const handleClickTab = () => {
    setDisplay(false);
  }
  
  return (
    <nav className="navbar navbar-expand-lg rewards-navbar navbar-light  show-mobile mt-0 mx-auto start-0 end-0">
      <div className="container-fluid px-0 navbar-container">
        <a className="navbar-brand p-2 ps-0" href="/#landing">
          <img src="/img/logo-dark.png" style={{ width: "73px", height: "73px" }} />
        </a>
        <button
          className="navbar-toggler rounded-0"
          type="button"
          onClick={() => setDisplay(!display)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="bg-light w-100 vh-100 drawer-menu overflow-hidden"
          style={{
            position: "fixed",
            top: '0',
            left: display ? 0 : "-100vw",
          }}
        >
          <div className="container-fluid px-2 px-md-3 pe-md-5">
            <div className="row justify-content-between align-items-center w-100 mx-auto">
              <a className="navbar-brand p-2 ps-0 w-max-content" href="/#landing">
                <img src="/img/logo-dark.png" style={{ width: "73px", height: "73px" }} />
              </a>

              <button
                className="navbar-toggler rounded-0"
                type="button"
                onClick={() => setDisplay(!display)}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
           
            <ul
              className="navbar-nav nav justify-content-center position-relative"
              style={{ paddingRight: "130px" }}
            >
              <li className={`nav-item nav-item-active`}>
                <a onClick={handleClickTab} className="nav-link" href="#scrollspyLevels">
                  NIVELES
                </a>
              </li>
              <li className={`nav-item `}>
                <a onClick={handleClickTab} className="nav-link" href="#scrollspySteps">
                  STEPS
                </a>
              </li>
              <li className="nav-item">
                <a onClick={handleClickTab} className="nav-link" href="#scrollspyRules">
                  MODALIDADES
                </a>
              </li>
             
              <li className="navbar-cta__container ms-md-3 position-absolute end-0 bottom-0 top-0">
                <Link
                  className="btn bold text-white rewards-gradient text-uppercase"
                  to="/entrar"
                >
                  <i className="fa fa-user"></i>{" "}
                  <span className="ms-1">Ingresar</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MobileNavbar;
