import React from "react";
import "./RewardsVideo.css";

export default function RewardsVideo() {
  return (
    <div className="container-fluid rewards-video position-relative">
      <h2 className="rewards-video-title  mb-3">Descubre Nuestra Plataforma en Acción</h2>
      <img
        className=""
        src="/img/video-prob.png"
      />
        <button style={{bottom: "-25px"}} className="feat-btn position-absolute start-0 end-0 mx-auto btn rewards-gradient">
          <span className="text-white ">
            COMIENZA YA

            <i className="fas fa-arrow-right ms-2"/>
          </span>
        </button>
    </div>
  );
}
