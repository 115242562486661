import React from "react";
import "../css/LandingRewards.css";
import RewardsHero from "../components/rewards/RewardsHero";
import RewardsVideo from "../components/rewards/RewardsVideo";
import RewardsRules from "../components/rewards/RewardsRules";
import MobileNavbar from "../components/rewards/MobileNavbar";
import RewardsNavbar from "../components/rewards/RewardsNavbar";
import RewardsLevels from "../components/rewards/RewardsLevels";
import RewardsBrands from "../components/rewards/RewardsBrands";
import RewardsFeatures from "../components/rewards/RewardsFeatures";

const LandingRewards = () => {
  return (
    <div
      id="landing"
      className="vw-100 position-relative"
      style={{ overflow: "hidden" }}
      data-bs-spy="scroll"
      data-bs-target="#rewards-landing"
      data-bs-root-margin="0px 0px -40%"
      data-bs-smooth-scroll="true"
      tabIndex="0"
    >
      <RewardsNavbar />
      <MobileNavbar />
      <RewardsHero />
      <RewardsLevels />
      <RewardsFeatures />
      <RewardsBrands />
      <RewardsVideo />
      <RewardsRules />
    </div>
  );
};

export default LandingRewards;
