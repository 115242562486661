import React, { useContext } from "react";
// import Confetti from "react-confetti";
import { ModalContext } from "../../context/ModalContext";

const Modal = () => {
  const {
    size,
    title,
    content,
    centered,
    children,
    onCancel,
    component,
    onSuccess,
    hideClose,
    clearModal,
    no_padding,
    show_confetti,
  } = useContext(ModalContext);

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
    clearModal();
  };

  const renderHeader = () => {
    const condition = title !== "" && !no_padding;
    if (condition) {
      return (
        <div className="modal-header">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-10">
                <h5 className="modal-title">{title}</h5>
              </div>
              <div className="col-2 text-end">
                {!hideClose && !no_padding && (
                  <button
                    onClick={handleCancel}
                    className="btn btn-sm text-muted"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const width = window.innerWidth;
  const height = window.innerHeight;

  return (
    <div
      className="modal fade"
      id="modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      {/* {show_confetti && <Confetti width={width} height={height} style={{ position: "absolute", left: 0, top: 0 }} />} */}
      <div
        className={`modal-dialog position-relative ${
          size && size !== null ? `modal-${size}` : ""
        } ${centered ? "modal-dialog-centered" : ""}`}
        role="document"
      >
        <div className="modal-content">
          {renderHeader()}
          {no_padding && !hideClose && (
              <button
                id="close-modal"
                onClick={handleCancel}
                className="btn btn-sm"
              >
                <i className="fa fa-times"></i>
              </button>
          )}
          <div className={`modal-body ${no_padding ? "px-0 py-0" : ""}`}>
            {content}
            {component}
            {children}
          </div>
          {(onSuccess || onCancel) && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={onSuccess}
              >
                OK
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
