import React from "react";
import "./RewardsFeatures.css";

export default function RewardsFeatures() {
  return (
    <div id="scrollspySteps" className="rewards-features">
      <h2 className="features__title">Tu Camino hacia el Éxito</h2>
      <p className="features__desc mb-5">
        Descubre cómo cada paso te brinda progreso visible, y facilita el canje
        de tus recompensas
      </p>

      <div className="row card-container container justify-content-between">
        <div className="card mb-3 mb-md-0">
          <h3 className="feat-title text-capitalize">PROGRESO VISIBLE</h3>
          <img className="feat-image" src="/img/feat-1.png" />
          <p className="feat-desc text-white">
            Con cada entrenamiento completado, nuestras alumnas ascienden de
            nivel, desbloqueando recompensas.
          </p>
        </div>

        <div className="card mb-3 mb-md-0">
          <h3 className="feat-title text-capitalize">COMUNIDAD ACTIVA</h3>
          <img className="feat-image" src="/img/feat-2.png" />
          <p className="feat-desc text-white">
            A través de nuestra plataforma digital, las alumnas interactúan con
            marcas como la tuya, descubriendo productos y servicios que
            complementan su estilo de vida saludable.
          </p>
        </div>

        <div className="card mb-3 mb-md-0">
          <h3 className="feat-title text-capitalize">CANJEE SENCILLO</h3>
          <img className="feat-image" src="/img/feat-3.png" />
          <p className="feat-desc text-white">
            Un sistema intuitivo permite a las alumnas canjear sus recompensas
            de forma fácil y rápida.
          </p>
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <button className="feat-btn btn ">
          <span className="rewards-text-gradient">
            COMIENZA YA

            <i className="fas fa-arrow-right ms-2"/>
          </span>
        </button>
      </div>
    </div>
  );
}
