import React, { useState } from "react";
import "./RewardsBrands.css";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "../global/EmblaCarouselArrowButtons";
import useEmblaCarousel from "embla-carousel-react";
import BrandsFeaturesCarousel from "./BrandsFeaturesCarousel";

export default function RewardsBrands() {
  const [showCarousel, setShowCarousel] = useState(false);
  const [currentBrand, setCurrentBrand] = useState();

  const options = { align: "start", loop: true };
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const brands = [
    {
      logo: "/img/brand-1.png",
      levels: [
        {
          number: 1,
          benefit: "10% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
          expiration: "1 SEMANA",
        },
        {
          number: 2,
          benefit: "10% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
          expiration: "1 SEMANA",
        },
        {
          number: 3,
          benefit: "15% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
          expiration: "1 SEMANA",
        },
        {
          number: 4,
          benefit: "15% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
          expiration: "1 SEMANA",
        },
        {
          number: 5,
          benefit: "20% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
          expiration: "1 SEMANA",
        },
        {
          number: 6,
          benefit: "25% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions: "SE APLICA SOBRE UN SOLO PRODUCTO",
          expiration: "1 SEMANA",
        },
      ],
      instagram: "@habitsproteins",
    },
    {
      logo: "/img/brand-2.png",
      levels: [
        {
          number: 1,
          benefit: "FREE SHIPPING",
        },
        {
          number: 2,
          benefit: "15% OFF",
        },
        {
          number: 3,
          benefit: "20% OFF",
        },
        {
          number: 4,
          benefit: "25% OFF",
        },
        {
          number: 5,
          benefit: "GIFT CARD $250",
        },
        {
          number: 6,
          benefit: "DERMOLIMPIADOR FACIAL FREE",
        },
      ],
      instagram: "@elisazambrano",
    },
    {
      logo: "/img/brand-3.png",
      levels: [
        {
          number: 1,
          benefit: "FREE SHIPPING",
          application: "CÓDIGO DE DESCUETNO",
          restrictions: "SE PUEDE USAR 2 VECES AL MES",
          expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 2 VECES AL MES.",
        },
        {
          number: 2,
          benefit: "10% OFF EN SITIO WEB",
          application: "CÓDIGO DE DESCUETNO",
          restrictions:
            "NO APLICA CON OTRAS PROMOCIONES, SE PUEDE USAR 2 VECES AL MES",
          expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 2 VECES AL MES.",
        },
        {
          number: 3,
          benefit: "10% OFF + TOTE BAG",
          application: "CÓDIGO DE DESCUETNO",
          restrictions:
            "NO APLICA CON OTRAS PROMOCIONES, SE PUEDE USAR 2 VECES AL MES TOTE BAG SOLO 1 VEZ",
          expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 2 VECES AL MES.",
        },
        {
          number: 4,
          benefit: "15% OFF EN SITIO WEB",
          application: "CÓDIGO DE DESCUETNO",
          restrictions:
            "NO APLICA CON OTRAS PROMOCIONES, SE PUEDE USAR 2 VECES AL MES",
          expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 2 VECES AL MES.",
        },
        {
          number: 5,
          benefit: "BUY 1 GET 1 FREE",
          application: "CÓDIGO DE DESCUETNO",
          restrictions:
            "NO APLICA CON OTRAS PROMOCIONES, SE PUEDE USAR 1 VEZ AL MES",
          expiration: "EL BENEFICIO ES VIGENTE LOS 2 MESES, 1 VEZ",
        },
        {
          number: 6,
          benefit: "SET GRATIS",
          application: "CÓDIGO DE DESCUETNO",
          restrictions:
            "SUBIR STORY CON EL SET PUESTO Y TAGGEAR A LA MARCA EN INSTAGRAM.",
          expiration: "UNICA VEZ",
        },
      ],
      instagram: "@feeleuphoria.wear",
    },
    {
      logo: "/img/brand-4.png",
      levels: [
        {
          number: 1,
          benefit: "10% OFF",
          application: "MOSTRAR NIVEL EN LA APP O WEB CON EL CUPÓN: TBM10OFF",
          restrictions: "CREA TU CUENTA Y AVÍANOS PARA ACTIVAR EL CUPÓN.",
          expiration: "N/A",
        },
        {
          number: 2,
          benefit: "15% OFF",
          application: "MOSTRAR NIVEL EN LA APP O WEB CON EL CUPÓN: TBM10OFF",
          restrictions: "CREA TU CUENTA Y AVÍANOS PARA ACTIVAR EL CUPÓN.",
          expiration: "N/A",
        },
        {
          number: 3,
          benefit: "20% OFF",
          application: "MOSTRAR NIVEL EN LA APP O WEB CON EL CUPÓN: TBM10OFF",
          restrictions: "CREA TU CUENTA Y AVÍANOS PARA ACTIVAR EL CUPÓN.",
          expiration: "N/A",
        },
        {
          number: 4,
          benefit: "20% OFF",
          application: "MOSTRAR NIVEL EN LA APP O WEB CON EL CUPÓN: TBM10OFF",
          restrictions: "CREA TU CUENTA Y AVÍANOS PARA ACTIVAR EL CUPÓN.",
          expiration: "N/A",
        },
        {
          number: 5,
          benefit: "UNA TANK TOP DE REGALO EN COMPRA DE 1,200 PESOS",
          application: "SOLO EN TIENDA, SUBIENDO STORY",
          restrictions: "NO SE PUEDE PAGINA WEB",
          expiration: "N/A",
        },
        {
          number: 6,
          benefit: "GIFT CARD DE 600 PESOS",
          application: "SOLO EN TIENDA, SUBIENDO STORY",
          restrictions: "NO SE PUEDE PAGINA WEB",
          expiration: "N/A",
        },
      ],
      instagram: "@mycrazyfit_",
    },
    {
      logo: "/img/brand-5.png",
      levels: [
        {
          number: 1,
          benefit: "10% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions:
            "APLICA A UN SOLO PRODUCTO DE MARCA BLOOM (SE DEBE SUBIR STORY)",
          expiration: "1 SEMANA",
        },
        {
          number: 2,
        },
        {
          number: 3,
          benefit: "15% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions:
            "APLICA A UN SOLO PRODUCTO DE MARCA BLOOM (SE DEBE SUBIR STORY)",
          expiration: "1 SEMANA",
        },
        {
          number: 4,
        },
        {
          number: 5,
          benefit: "20% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions:
            "APLICA A UN SOLO PRODUCTO DE MARCA BLOOM (SE DEBE SUBIR STORY)",
          expiration: "1 SEMANA",
        },
        {
          number: 6,
          benefit: "20% OFF",
          application: "MOSTRANDO UN CÓDIGO",
          restrictions:
            "APLICA A UN SOLO PRODUCTO DE MARCA BLOOM (SE DEBE SUBIR STORY)",
          expiration: "1 SEMANA",
        },
      ],
      instagram: "@BLOOMSUPERFOODS",
    },
    {
      levels: [
        {
          number: 1,
          benefit: "10% OFF",
          application: "CÓDIGO: TBM10",
          restrictions: "COMPRA MÍNIMA $800 PESOS",
        },
        {
          number: 2,
          benefit: "10% OFF",
          application: "CÓDIGO: TBM10",
          restrictions: "COMPRA MÍNIMA $800 PESOS",
        },
        {
          number: 3,
          benefit: "15% OFF",
          application: "CÓDIGO: 15TBM",
          restrictions: "COMPRA MÍNIMA $800 PESOS",
        },
        {
          number: 4,
          benefit: "15% OFF",
          application: "CÓDIGO: 15TBM",
          restrictions: "COMPRA MÍNIMA $800 PESOS",
        },
        {
          number: 5,
          benefit: "20% OFF",
          application: "WIN20TBM",
          restrictions: "COMPRA MÍNIMA $800 PESOS",
        },
        {
          number: 6,
          benefit: "20% OFF",
          application: "WIN20TBM",
          restrictions: "COMPRA MÍNIMA $800 PESOS",
        },
      ],
      instagram: "@Fiera_activewear",
    },
  ];

  return (
    <div className="container rewards-brands position-relative">
      <h2 className=" brands-title mb-3">NUESTRAS MARCAS</h2>
      <p className="brands-desc">Conoce las marcas participantes</p>

      <section className="carousel__rewards-brands">
        <div className="rewards-brands__viewport" ref={emblaRef}>
          <div className="rewards-brands__container">
            {brands.map((brand, index) => (
              <div
                key={index}
                className="brand-card rewards-brands__slide d-flex flex-column align-items-center justify-content-center"
              >
                <img alt="brand" src={brand.logo} />
                <button
                  type="button"
                  className="brand-btn btn mt-2"
                  onClick={() => {
                    brand.index = index;
                    setCurrentBrand(brand);
                    setShowCarousel(true);
                  }}
                >
                  +
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="rewards-brands__controls">
          <div className="rewards-brands__buttons">
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </div>

        </div>
      </section>

      {showCarousel && currentBrand && (
        <BrandsFeaturesCarousel
          selectedBrand={currentBrand}
          showCarousel={showCarousel}
          setShowCarousel={setShowCarousel}
        />
      )}
    </div>
  );
}
