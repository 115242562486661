import { useEffect, useContext, useState } from "react";
import {
  getClassCategoryDay,
  getStartEndDate,
  getTarget,
} from "../utils/workout_planner";
import { CustomerWorkoutPlansContext } from "../context/CustomerWorkoutPlansContext";
import { ClassCategoryContext } from "../context/ClassCategoryContext";
import { WorkoutPlansContext } from "../context/WorkoutPlansContext";
import { ReservationsContext } from "../context/ReservationsContext";
import { PlaybacksContext } from "../context/PlaybacksContext";
import { FiltersContext } from "../context/FiltersContext";
import { navigate } from "@reach/router";
import moment from "moment";
import { ClassTypeContext } from "../context/ClassTypesContext";
import { VideosContext } from "../context/VideosContext";

const useWorkoutPlanner = () => {
  const [progress, setProgress] = useState(null);
  const [categories, setCategories] = useState([]);
  const [calculating, setCalculating] = useState(false);
  const [randomFilters, setRandomFilters] = useState({});

  const { setFilter } = useContext(FiltersContext);
  const { videos, getVideos } = useContext(VideosContext);
  const { class_types, getClassTypes } = useContext(ClassTypeContext);
  const { playbacks, getPlaybacksByDate } = useContext(PlaybacksContext);
  const { reservations, getMyReservations } = useContext(ReservationsContext);
  const { customer_workout_plan, setPropertyCustomerWorkoutPlan } = useContext(
    CustomerWorkoutPlansContext
  );
  const { workout_plan, setWorkoutPlan } = useContext(WorkoutPlansContext);
  const { class_categories, getClassCategories } =
    useContext(ClassCategoryContext);

  /**
   * Retrieve reservations to render completed days
   * Retrieve class categories to render on right column
   */
  useEffect(() => {
    if (!Array.isArray(reservations)) {
      getMyReservations();
    }

    if (!Array.isArray(class_categories)) {
      getClassCategories();
    }

    if (!Array.isArray(class_types)) {
      getClassTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Retrieve playbacks to render completed days
   */
  useEffect(() => {
    if (customer_workout_plan && customer_workout_plan !== null) {
      const { end_date } = customer_workout_plan;
      if (end_date === null) {
        handleStartEndDate();
      } else {
        setWorkoutPlan(customer_workout_plan.workout_plan);
      }
      if (end_date !== null && !Array.isArray(playbacks)) {
        const { start_date } = customer_workout_plan;
        getPlaybacksByDate(start_date, end_date);
      }
      // handleFirstDayRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_workout_plan]);

  /**
   * Retrieve workout plan categories every time the workout planner changes
   */
  useEffect(() => {
    if (
      workout_plan &&
      workout_plan !== null &&
      Array.isArray(class_categories)
    ) {
      const currentCategories = handleCategories(
        workout_plan.workout_plan_days
      );
      setCategories(currentCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workout_plan]);

  useEffect(() => {
    if (
      Array.isArray(playbacks) &&
      Array.isArray(reservations) &&
      customer_workout_plan !== null
    ) {
      if (
        customer_workout_plan.end_date !== null &&
        progress === null &&
        !calculating
      ) {
        setCalculating(true);
        const { start_date, end_date } = customer_workout_plan;
        getProgress(start_date, end_date);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbacks, reservations, customer_workout_plan]);

  useEffect(() => {
    if (Object.keys(randomFilters).length > 0) {
      fetchVideos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomFilters]);

  useEffect(() => {
    if (Array.isArray(videos) && Object.keys(randomFilters).length > 0) {
      const firstVideo = videos[0];
      if (firstVideo && firstVideo !== null) {
        navigate(`/mytbm/video/${firstVideo.video_id}`);
        setRandomFilters({});
      } else {
        fetchVideos();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos]);

  const fetchVideos = () => getVideos(randomFilters);

  /**
   * When workout planner's start date is same as current date
   * And today is a workout day, redirect to TBM Online
   */
  // const handleFirstDayRedirect = () => {
  //   const start_date = customer_workout_plan?.start_date;
  //   const currentDayStart = moment().startOf("day");
  //   const startDay = moment(start_date).startOf("day");
  //   if (startDay.isSame(currentDayStart)) {
  //     handleDayClass(1, 1);
  //   }
  // };

  /**
   *
   * @returns True if both elements are not null
   */
  const loadedWorkoutPlanner = () => {
    return (
      customer_workout_plan &&
      customer_workout_plan !== null &&
      workout_plan &&
      workout_plan !== null
    );
  };

  /**
   * User only sets workout planner's start date
   * Calculate workout planner's end date
   */
  const handleStartEndDate = () => {
    const { start_date, end_date } = getStartEndDate(customer_workout_plan);
    setPropertyCustomerWorkoutPlan("start_date", start_date);
    setPropertyCustomerWorkoutPlan("end_date", end_date);
    setWorkoutPlan(customer_workout_plan.workout_plan);
  };

  /**
   * Monthly progress to display on progress bar
   * @param {*} start_date
   * @param {*} end_date
   */
  const getProgress = (start_date, end_date) => {
    const { frequency } = customer_workout_plan.workout_plan;
    const original = moment(start_date);
    const initial = moment(start_date);
    const end = moment(end_date);
    const total = Math.ceil(((end.diff(original, "days") + 1) / 7) * frequency);
    let completedDays = 0;
    while (initial.isBefore(end)) {
      const currentDay = initial.format("YYYY-MM-DD");
      const completed = hasCompletedDay(currentDay);
      if (completed) completedDays++;
      initial.add(1, "day");
    }
    setProgress(Math.ceil((completedDays / total) * 100));
    setCalculating(false);
  };

  /**
   * Determine if the user has completed workout planner day
   * @param {*} dateString
   * @param {*} playbacks
   * @param {*} reservations
   * @returns
   */
  const hasCompletedDay = (dateString) => {
    if (Array.isArray(playbacks)) {
      const currentPlaybacks = playbacks.find(({ day }) => day === dateString);
      if (currentPlaybacks && currentPlaybacks !== null) {
        if (currentPlaybacks.playbacks > 0) return true;
      }
    }
    if (Array.isArray(reservations)) {
      const dateReservations = reservations.filter(
        ({ single_class }) =>
          moment(single_class.class_date).format("YYYY-MM-DD") === dateString
      );
      if (dateReservations.length > 0) {
        let attended = dateReservations.filter(({ attend }) => attend);
        return attended.length > 0;
      }
    }
    return false;
  };

  /**
   * Filter TBM Online classes by dayIndex and weekIndex
   * Redirect user after filtering
   * @param {*} weekIndex
   * @param {*} dayIndex
   */
  const handleDayClass = (weekIndex, dayIndex) => {
    const hasDay = getClassCategoryDay(workout_plan, dayIndex);
    if (hasDay) {
      const target = getTarget(
        hasDay.class_category.name,
        workout_plan.frequency,
        weekIndex,
        dayIndex
      );
      if (target) {
        handleClassFilters(hasDay.class_category.class_category_id, target);
      }
      navigate("/mytbm/online");
    }
  };

  /**
   * Filter TBM Online classes by class_category_id and target
   * @param {*} class_category_id
   * @param {*} target
   */
  const handleClassFilters = (class_category_id, target) => {
    //clearFilters();
    if (target === "Full Body") {
      setFilter("tag_id", 1);
    } else if (target === "Lower") {
      setFilter("tag_id", 2);
    } else if (target === "Upper") {
      setFilter("tag_id", 4);
    }
    setFilter("class_category_id", class_category_id);
  };

  const handleTargetFilter = (target) => {
    if (target === "Full Body") {
      setFilter("tag_id", 1);
      return 1;
    } else if (target === "Lower") {
      setFilter("tag_id", 2);
      return 2;
    } else if (target === "Upper") {
      setFilter("tag_id", 4);
      return 3;
    }
  };

  /**
   * Get a random class
   */
  const handleRandomClass = () => {
    const hasDay = getClassCategoryDay(workout_plan);
    if (hasDay) {
      const target = getTarget(
        hasDay.class_category.name,
        workout_plan.frequency
      );
      console.log({ class_types });
      console.log({ hasDay });
      const class_types_ids = class_types
        .filter((type) => type.class_category_id === hasDay.class_category.class_category_id)
        .map(({ class_type_id }) => class_type_id);
      console.log({ class_types_ids });
      const random = Math.floor(Math.random() * class_types_ids.length) + 1;
      console.log({ random });
      const result_id = class_types_ids[random];
      setFilter("class_type_id", result_id);
      setRandomFilters({
        class_category_id: hasDay.class_category.class_category_id,
        program_id: workout_plan.program_id,
        tag_id: handleTargetFilter(target),
        class_type_id: result_id,
        random: true,
      });
    }
  };

  /**
   * Filter TBM Online classes by class_type_id
   * Redirect user to TBM Online after filtering
   * @param {*} class_type_id
   */
  const handleClassTypeFilters = (class_type_id) => {
    const hasDay = getClassCategoryDay(workout_plan);
    if (hasDay) {
      const target = getTarget(
        hasDay.class_category.name,
        workout_plan.frequency
      );
      if (Array.isArray(class_types)) {
        let currentClassType = class_types.find(
          (type) => parseInt(type.class_type_id) === parseInt(class_type_id)
        );
        if (currentClassType) {
          if (currentClassType.class_category_id !== hasDay.class_category_id) {
            handleTargetFilter(target);
            setFilter("class_category_id", null);
            navigate(`/mytbm/online/clases/${class_type_id}`);
          }
        }
      }
      if (target) {
        handleClassFilters(hasDay.class_category.class_category_id, target);
      }
    }
    setFilter("class_category_id", null);
    navigate(`/mytbm/online/clases/${class_type_id}`);
  };

  /**
   * Find class categories in a workout planner
   */
  const handleCategories = (workout_plan_days) => {
    // Sort days by date
    const days = workout_plan_days.sort((a, b) => (a.day < b.day ? -1 : 1));
    // Loop days to add class categories to set
    // Set is created to avoid repeating categories
    let currentCategories = new Set();
    days.forEach((day) => {
      currentCategories.add(day.class_category.class_category_id);
    });

    // Loop through set to find the full category object
    let categoriesResult = [];
    currentCategories.forEach((class_category_id) => {
      const currentCategory = class_categories.find(
        (class_category) =>
          class_category.class_category_id === class_category_id
      );
      if (currentCategory) categoriesResult.push(currentCategory);
    });

    // Set the resulting array of non-repeating categories
    return categoriesResult;
  };

  return {
    progress,
    categories,
    workout_plan,
    handleDayClass,
    hasCompletedDay,
    handleCategories,
    handleRandomClass,
    handleClassFilters,
    loadedWorkoutPlanner,
    customer_workout_plan,
    handleClassTypeFilters,
  };
};

export default useWorkoutPlanner;
